.BackOfHouse {
    .product-sales-container {
        display: flex;

        flex-direction: column;
        align-items: center;

        width: calc(1920px - 752px);
        height: 100%;

        box-sizing: border-box;
        padding-right: 1%;

        .header {
            width: 80%;

            .title {
                font-size: 62px;
                text-align: left;
                text-transform: uppercase;
                margin-bottom: 8px;
                min-height: 1em;
                line-height: 1;
            }

            .description {
                font-size: 32px;
                text-align: left;
                margin: 0;
                color: inherit;
            }
        }

        .product-sales-list {
            display: flex;
            flex-direction: column;

            width: 80%;
            margin-top: 36px;

            .list-row {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                font-weight: 400;
                height: 28px;

                .locations {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin: 0;
                    font-weight: 600;
                }

                &.list-header {
                    padding: 21px;
                    color: #fafafa;
                    display: flex;
                    align-items: center;

                    .location_header {
                        width: 317px;
                    }

                    p {
                        font-size: 32px;
                    }
                }

                &.list-item-own {
                    position: relative;

                    &:before {
                        content: '';
                        position: absolute;
                        opacity: 0.3;
                        top: 0;
                        bottom: 0;
                        right: 0;
                        left: 0;
                        background-color: var(--accent-color)

                    }
                }

                &.list-item {
                    height: 65px;
                    padding: 14px 21px;
                    border-bottom: 1px solid #6E6E6E;
                    font-weight: 600;

                    .name {
                        font-size: 32px;
                        width: 435px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                    .new-item {
                        font-size: 32px;
                        z-index: 1;
                    }

                    .count {
                        width: 230px;
                        display: flex;
                        justify-content: flex-end;
                        font-size: 32px;

                    }

                    .amount {
                        width: 325px;
                        display: flex;
                        justify-content: flex-end;
                        font-size: 32px;
                        text-transform: capitalize;


                    }
                }
            }
        }
    }
}
