.DefaultCta {
    box-sizing: border-box;
    background: var(--cta-background);
    background-color: var(--cta-background-color);
    color: var(--text-color);
    font-weight: bold;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .pwby-logo {
        width: 100%;
        margin: 5px;
        margin-bottom: 20px;
        height: 70px;
        text-align: center;
        font-size: 70px;
    }
}