@import url("https://fonts.googleapis.com/css?family=Raleway:400,400i,700");

.frame {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 2px;
    box-shadow: 4px 8px 16px 0 rgba(0, 0, 0, 0.1);
    overflow: hidden;
    background: #0c589e;
    color: white;
    font-family: Raleway, Helvetica, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    display: flex;
    justify-content: center;
    align-items: center;
}

.button {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 40px;
    cursor: pointer;

    svg {
        height: 110px;
        width: 900px;
        fill: none;
        stroke-width: 5;

        .o1 {
            stroke: rgba(#0C2D59, 0.7);
            fill: #0c589e;
            transition: all 1s ease-in-out;
        }

        .o2 {
            stroke: white;
            stroke-dasharray: 20 1020;
            stroke-dashoffset: 20;
            transition: all 1s ease-in-out;
        }
    }

    span {
        position: absolute;
        margin: auto 0;
        text-transform: uppercase;
        letter-spacing: 3px;
    }

    &:hover {
        .o1{
            fill: rgba(#0C2D59, 0.3);
        }

        .o2 {
            stroke-dashoffset: -1020;
        }
    }
}
