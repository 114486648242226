.ZoomCta {
    position: relative;
    height: 100%;

    .ZoomDescriptionCta, .ZoomImageCta, .ZoomTextCta {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: var(--zoom-background);
        background-color: var(--zoom-background-color);
        color: var(--zoom-text-color);
    }

    .ZoomDescriptionCta {
        box-sizing: border-box;
        padding: 80px 65px 25px;
    }

}