.SocialZoom {
    position: relative;
    height: 100%;
    width: 100%;

    .cta {
        position: absolute;
        top: 0;
        left: 0;
        bottom: auto;
        right: auto;
        width: 43.75%;
        height: 100%;
    }

    .media {
        position: absolute;
        top: 0;
        right: 0;
        bottom: auto;
        left: auto;
        width: 56.25%;
        height: 100%;
        overflow: hidden;

        .ImageZoom, .VideoZoom {
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: contain;
        }

        .VideoZoom {
            position: relative;
            display: block;
            > video {
                box-sizing: border-box;
                display: inline-block !important;
            }
        }

        img.background {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            object-fit: cover;
            transform: scale(1.06);

            &.blur {
                filter: blur(25px);
            }

            &.opacity {
                opacity: 0.4;
            }
        }
    }

    .LogoZoom {
        position: absolute;
        right: 50px;
        width: 300px;
        height: 150px;
        bottom: 30px;
        z-index: 500;
        text-align: right;

        img {
            height: auto;
            max-height: 100%;
            margin-left: auto;
            margin-top: auto;
            object-fit: contain;
        }

        &.defaultLogoZoom {
            img {
                display: none;
            }
        }
    }

    .attribution {
        // Positioning
        position: absolute;
        bottom: 30px;
        left: 20px;
        z-index: 3;
  
        // Sizing
        box-sizing: border-box;
        width: calc(100% - 370px);
        height: 65px;
        padding: 20px;
  
        // Layout
        display: flex;
        flex-direction: row;
        align-items: center;
  
        // Items
        i {
            color: white;
            font-size: 58px;
            margin-right: 10px;
        }
  
        .username {
            height: 65px;
            width: calc(100% - 60px);
            color: white;
            font-family: 'Roboto', sans-serif;
            font-weight: 400;
        }
    }

    &.vertical {
        .cta {
            position: absolute;
            bottom: 0;
            left: 0;
            top: auto;
            right: auto;
            width: 100%;
            height: 43.75%;
        }

        .media {
            position: absolute;
            top: 0;
            left: 0;
            bottom: auto;
            right: auto;
            width: 100%;
            height: 56.25%;
        }
    }
    
}