.BackOfHouse {
    .thermometer-container {
        position: absolute;

        .thermometer {
            position: relative;

            .thermometer-asset {
                position: relative;
            }

            .thermometer-background {
                .thermometer-background-base {
                    background-image: linear-gradient(to top, #ff3d50, #ff5e3f, #ff7c2e, #ff981c);
                    background-repeat: no-repeat;
                    position: absolute;
                    bottom: 14px;
                    left: 50%;
                    transform: translateX(-50%);
                    width: 155px;
                    height: 155px;
                    border-radius: 40%;
                }

                .thermometer-tube-container {
                    background-image: linear-gradient(
                                    to top,
                                    #ff981c,
                                    #ffb40f,
                                    #f4c212,
                                    #e8d01f,
                                    #dadd31,
                                    #bfde45,
                                    #a6dd58,
                                    #8edb6b,
                                    #77d87c
                    );
                    background-repeat: no-repeat;
                    background-size: 80px 430px;
                    background-position-y: bottom;
                    position: absolute;
                    bottom: 168px;
                    left: 50%;
                    transform: translateX(-50%);
                    width: 80px;
                    border-radius: 10%;
                    height: 0px;

                    .thermometer-tube {
                        position: relative;

                        .thermometer-message {
                            position: absolute;
                            top: 0;
                            right: 165px;
                            display: flex;
                            flex-direction: column;
                            align-items: flex-end;
                            white-space: nowrap;
                            transform: translateY(-50px);

                            &__description {
                                font-size: 38px;
                            }

                            &__value {
                                font-size: 52px;
                            }

                            .fill-indicator {
                                position: absolute;
                                content: '';
                                display: block;
                                width: 80px;
                                height: 6px;
                                left: 105%;
                                top: 40%;
                            }
                        }
                    }
                }
            }

            .thermometer-description {
                display: flex;
                font-size: 38px;
                position: absolute;
                top: 0;
                left: 180px;
                white-space: nowrap;

                &__text {
                    margin-right: 14px;
                }
            }

            .thermometer-value {
                font-size: 52px;
                position: absolute;
                top: 50px;
                left: 180px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                white-space: nowrap;
            }
        }
    }
}
