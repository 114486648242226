.S2MSpotlight {
  width: 100%;
  height: 100%;

  overflow: hidden;
  font-family: Cabin, sans-serif;

  .cta{
    float: left;
    width: 840px;
    height: 1080px;
    overflow: auto;

    .top{
      float: left;
      width: 100%;
      text-align: center;
      height: 650px;
      color: var(--cta-background-color);
      background-color: var(--text-color);
      font-style: normal;
      font-stretch: normal;
      line-height: 1.23;

      h1{
        margin-top: 170px;
        font-size: 70px;
        font-weight: bold;
      }

      h2{
        font-size: 45px;
        font-weight: normal;
      }
    }

    .bottom{

      :first-child{
        margin-top: 60px;
      }

      text-align: center;
      font-size: 35px;
      font-style: normal;
      font-stretch: normal;
      font-weight: normal;
      line-height: 1.5;
      float: left;
      width: 100%;
      height: 430px;
      background-color: var(--cta-background-color);
      color: var(--text-color);
      text-transform: uppercase;

      .url, .code{
        text-transform: none;
        font-weight: bold;
        font-size: 70px;
      }

      .url {
        margin-bottom: 50px;
      }
    }
  }

  .image{
    float: left;
    height: 1080px;
    width: 1080px;
    background: url("https://s3.amazonaws.com/static.upshow.tv/llama.jpg");
    background-size: cover;
  }
}