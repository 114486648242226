#root {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  
  > .container {
    background: var(--background);
    width: 100%;
    height: 100%;
    position: absolute;
    backface-visibility: hidden;

    &.hidden {
      opacity: 0;
    }
  }
}

html:not(.AFTRS):not(.AFTT):not(.AFTS):not(.AFTM):not(.AFTB) {
  body {
    #root {
      perspective: 1500px;
      transform-style: preserve-3d;
    }
  }
}
