.Loading {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white;
    background-color: #2f2f2f;

    .Lottie {
        width: 100%;
        height: 100%;
    }
}

