.BackOfHouse {
    .training-status-container {
        display: flex;

        flex-direction: column;
        align-items: center;

        width: calc(1920px - 752px);
        height: 100%;

        box-sizing: border-box;
        padding-right: 1%;

        .header {
            width: 80%;

            .title {
                font-size: 62px;
                text-align: left;
                text-transform: uppercase;
                margin-bottom: 8px;
            }

            .description {
                font-size: 32px;
                text-align: left;
                margin: 0;
            }
        }

        .training-status-list {
            display: flex;
            flex-direction: column;

            width: 80%;
            margin-top: 36px;

            .list-header {
                padding: 21px;
                color: #fafafa;

                display: flex;
                flex-direction: row;
                justify-content: space-between;

                p {
                    font-size: 26px;
                    margin: 0;
                }
            }

            .list-item {
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                padding: 14px 21px;
                border-bottom: 1px solid #6E6E6E;

                text-transform: capitalize;
                height: 28px;

                p {
                    font-size: 28px;
                    margin: 0;
                    text-transform: capitalize;
                    font-weight: bold;

                    &.completed, &.incompleted {
                        margin-right: 46px;
                    }

                    &.completed {
                        color: #00AE4C
                    }

                    &.incompleted {
                        color: #C2344A
                    }
                }
            }
        }
    }
}
