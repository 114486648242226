.debug-console{
    background: rgba(0,0,0,0.5);
    padding: 15px;
    position: absolute;
    right: 15px;
    bottom: 100px;
    width: calc(100% - 60px);
    li{
        list-style: none;
        color: white;
        font-size: 2em;
        border-bottom: 1px solid white;
    }
}