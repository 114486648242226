.BackOfHouse {
    .avg-ticket-container {
        display: flex;

        flex-direction: column;
        align-items: center;

        width: calc(1920px - 752px);
        height: 100%;

        box-sizing: border-box;
        padding-right: 1%;

        .header {
            width: 80%;

            .title {
                font-size: 62px;
                text-align: left;
                text-transform: uppercase;
                margin-bottom: 8px;
                min-height: 1em;
                line-height: 1;
            }

            .description {
                font-size: 32px;
                text-align: left;
                margin: 0;
                color: inherit;
            }
        }

        .avg-ticket-list {
            display: flex;
            flex-direction: column;

            width: 80%;
            margin-top: 36px;

            .list-row {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                font-weight: 400;
                height: 28px;

                p {
                    margin: 0;
                }

                &.list-header {
                    height: 28px;
                    padding: 21px;
                    color: #fafafa;
                    display: flex;
                    align-items: center;

                    p {
                        font-size: 32px;
                    }
                }

                &.list-item-own {
                    position: relative;

                    &:before {
                        content: '';
                        position: absolute;
                        opacity: 0.3;
                        top: 0;
                        bottom: 0;
                        right: 0;
                        left: 0;
                        background-color: var(--accent-color)

                    }
                }

                &.list-item {
                    height: 65px;
                    padding: 14px 21px;
                    border-bottom: 1px solid #6E6E6E;

                    p {
                        font-size: 32px;
                        font-weight: 600;
                        text-transform: capitalize;

                        .ranking-prefix {
                            text-align: right;
                        }
                    }
                }
            }
        }
    }
}
