.MediaLikeTemplate {
    padding: 10px;
    height: 100%;

    .MediaTop {
        width: 100%;
        height: calc(75% - 10px);
        display: flex;
        justify-content: space-around;

        .bigbox {
            flex: 1;
            height: 100%;
            width: calc(100% - 25% - 10px);
            box-sizing: border-box;

            .Spotlight {
                min-height: 100%;
                .ImageSpotlight,
                .SpotlightOverlay {
                    width: 100%;
                    height: 100%;
                }
            }

            .SpotlightV2Wrapper {
                height: 1080px;
                width: 1920px;

                transform: scale(.742);
                transform-origin: left top;
            }
        }
        .side {
            height: 100%;
            margin-left: 10px;
            width: calc(25% - 10px);

            .Cta {
                height: 100%;
                .pwby-logo {
                    transform: scale(.6);
                }
            }
        }
    }

    .MediaBottom {
        width: 100%;
        height: calc(25% - 20px);
        display: flex;

        .TileGrid3 {
            flex: 7;
        }
        .Logo {
            padding: 10px 0;
            height: 100%;
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;

            img {
                display: block;
                padding: 0;
                margin: 0;
                max-height: 100%;
                max-width: 100%;
                height: auto;
                object-fit: contain;
            }

            &.defaultLogo {
                img {
                    content: var(--upshow-logo);
                }
            }
        }
        &.LongCta {
            .Cta {
                width: 75%;
                height: 100%;
                padding-top: 20px;
                padding-bottom: 10px;
                flex-direction: column;
                text-align: center;

                .pwby-logo {
                    display: none;
                }
            }
            .Logo {
                position: relative;
                width: 25%;
                img {
                    margin: auto;
                }
            }
        }
    }
}