.TileGrid3 {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;

    .box {
        .DefaultCta, .Tile3 {
            height: 100%;
            width: 100%;
        }
    }

}