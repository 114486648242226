.BackOfHouse {
    .sales-container {
        display: flex;

        flex-direction: column;
        align-items: center;

        width: calc(1920px - 752px);
        height: 100%;

        box-sizing: border-box;
        padding-right: 1%;

        .header {
            width: 85%;

            .title {
                font-size: 62px;
                text-align: left;
                text-transform: uppercase;
                margin-bottom: 8px;
                min-height: 1em;
                line-height: 1;
            }

            .description {
                font-size: 24px;
                text-align: left;
                margin: 0;
                color: inherit;
            }
        }

        .ticket-list {
            display: flex;
            flex-direction: column;

            width: 85%;
            margin-top: 36px;

            .list-row {
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                align-items: center;
                font-weight: 400;
                height: 28px;

                .header-location {
                    width: 13%;
                    text-align: end;
                }

                .header-previous {
                    width: 35%;
                    text-align: end;
                }

                .header-current {
                    width: 27%;
                    text-align: end;
                }

                .header-variation {
                    width: 24%;
                    text-align: end;
                }

                .location {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin: 0;
                    font-weight: 600;

                    .new-item {
                        font-size: 36px;
                        font-weight: bold;
                        z-index: 1;
                    }
                }

                &.list-header {
                    padding: 21px;
                    color: #fafafa;
                    display: flex;
                    align-items: center;
                    text-transform: capitalize;

                    p {
                        font-size: 32px;
                    }
                }

                &.list-item-own {
                    position: relative;

                    &:before {
                        content: '';
                        position: absolute;
                        opacity: 0.3;
                        top: 0;
                        bottom: 0;
                        right: 0;
                        left: 0;
                        background-color: var(--accent-color)

                    }
                }

                &.list-item {
                    height: 65px;
                    padding: 14px 21px;
                    border-bottom: 1px solid #6E6E6E;
                    font-weight: bold;

                    .location {
                        width: 18%;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin: 0;
                        font-size: 32px;

                        .new-item {
                            font-size: 32px;
                            z-index: 1;
                        }
                    }

                    .current-sales {
                        width: 27%;
                        display: flex;
                        justify-content: flex-end;
                        font-size: 32px;
                        text-transform: capitalize;
                    }

                    .previous-sales {
                        width: 30%;
                        display: flex;
                        justify-content: flex-end;
                        font-size: 32px;
                        text-transform: capitalize;
                        text-align: end;
                    }

                    .variance-column {
                        width: 14%;
                        padding-left: 10%;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;

                        .variance {
                            display: flex;
                            align-items: center;
                            font-size: 28px;

                            &.positive {
                                color: #00B23C;

                                .arrow {
                                    border-bottom-color: #00B23C;
                                }
                            }

                            &.negative {
                                color: #DE425B;

                                .arrow {
                                    border-bottom-color: #DE425B;
                                }
                            }

                            .arrow {
                                width: 0;
                                height: 0;
                                border-left: 13px solid transparent;
                                border-right: 13px solid transparent;

                                &.up {
                                    border-bottom: 22px solid;
                                }

                                &.down {
                                    border-top: 22px solid;
                                }
                            }
                        }
                    }

                }
            }

            .results-description {
                font-size: 24px;
                text-align: right;
                margin: 0;
                color: inherit;
                font-style: italic;
                margin-top: 14px;
                opacity: 0.7;
            }
        }

    }
}
