.debugger{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.66);
    width: 100%;
    height: 500px;
    overflow: hidden;

    .dinfo{
        font-size:12px;
        color: greenyellow;
    }

    .error{
        color: red;
    }

    div{
        color: white;
        font-size: 25px;
        font-weight: 100;
    }

}