.TileFace {
    overflow: hidden;

    video, img {
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;

        &.no-crop {
            object-fit: contain;
        }
    }

    img.background {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;

        &.blur {
            filter: blur(25px);
        }

        &.opacity {
            opacity: 0.4;
        }
    }
}