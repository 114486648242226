.iframe {
    overflow: hidden;
    height: 100%;
    iframe {
        width: 100%;
        height: 100%;
        overflow: hidden;
        border: none;
    }
}

