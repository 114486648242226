.Tile3 {
    flex: 1 1 auto;
    position: relative;

    .TileFace {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}