.ZoomImageCta {

    .image {
        position: absolute;
        z-index: 5;
        display: block;
        height: 100%;
        max-width: 100%;
        max-height: 100%;
    }

    .attribution {
        box-sizing: border-box;
        position: absolute;
        padding: 0 65px;
        z-index: 10;
        bottom: 170px;
        left: 0;
        width: 100%;

        .wrapper {
            margin: 0 auto;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 50px;
            font-weight: 700;
            height: 197px;

            i {
                margin-right: 8px;
                font-size: 32px;
            }

            img {
                width: 197px;
                height: 197px;
                object-fit: cover;
                border-radius: 50%;
                margin-left: 56px;
            }
        }
    }

}