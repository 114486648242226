:root.theme-light {
    --background: #ececec;
    --background-color: #ececec;
    --cta-background-color: #21445e;
    --text-color: #ffffff;
    --hashtags-color: #17b0f4;
    --pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_light.svg');
    --zoom-pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_dark.svg');
    --upshow-logo: url('https://cdn.upshow.tv/ui4_assets/logo_dark.png');
    --upshow-tv-logo: url('https://cdn.upshow.tv/ui4_assets/upshow_tv_logo_black.png');
    --logo-bars-color: #21445e;
    --long-hashtag-cta-color: #21445e;
    --zoom-background-color: #ececec;
    --zoom-text-color: #21445e;
}

:root.theme-dark {
    --background: #f8f8f8;
    --background-color: #f8f8f8;
    --cta-background-color: #353535;
    --text-color: #ffffff;
    --upshow-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_dark.svg');
    --upshow-tv-logo: url('https://cdn.upshow.tv/ui4_assets/upshow_tv_logo_black.png');
    --long-hashtag-cta-color: #353535;
    --zoom-background-color: #353535;
}

:root.theme-green {
    --background: #ececec;
    --background-color: #ececec;
    --cta-background-color: #ffffff;
    --text-color: #20824e;
    --hashtags-color: #676767;
    --pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_dark.svg');
    --zoom-pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_dark.svg');
    --upshow-logo: url('https://cdn.upshow.tv/ui4_assets/logo_dark.png');
    --upshow-tv-logo: url('https://cdn.upshow.tv/ui4_assets/upshow_tv_logo_black.png');
    --upshow-tv-logo-upnext: url('https://cdn.upshow.tv/ui4_assets/upshow_tv_logo_black.png');
    --logo-bars-color: #676767;
    --long-hashtag-cta-background-color: transparent;
    --long-hashtag-cta-color: #20824e;
    --long-hashtag-hashtags-color: #676767;
    --zoom-background-color: #ffffff;
    --zoom-text-color: #20824e;
}

:root.theme-red {
    --background: #f8f8f8;
    --background-color: #f8f8f8;
    --cta-background-color: #cf232e;
    --text-color: #ececec;
    --hashtags-color: #ececec;
    --pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_light.svg');
    --zoom-pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_light.svg');
    --upshow-logo: url('https://cdn.upshow.tv/ui4_assets/logo_dark.png');
    --upshow-tv-logo: url('https://cdn.upshow.tv/ui4_assets/upshow_tv_logo_black.png');
    --logo-bars-color: #cf232e;
    --long-hashtag-cta-background-color: transparent;
    --long-hashtag-cta-color: #cf232e;
    --long-hashtag-hashtags-color: #cf232e;
    --long-hashtag-display-separator: block;
    --zoom-background-color: #cf232e;
    --zoom-text-color: #f8f8f8;
}

:root.theme-cream {
    --background: #ffffff;
    --background-color: #ffffff;
    --cta-background-color: #5b1200;
    --text-color: #fff1da;
    --hashtags-color: #9b531d;
    --pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_light.svg');
    --zoom-pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_light.svg');
    --upshow-logo: url('https://cdn.upshow.tv/ui4_assets/logo_dark.png');
    --upshow-tv-logo: url('https://cdn.upshow.tv/ui4_assets/upshow_tv_logo_black.png');
    --logo-bars-color: #5b1200;
    --long-hashtag-cta-background-color: transparent;
    --long-hashtag-cta-color: #5b1200;
    --long-hashtag-hashtags-color: #5b1200;
    --long-hashtag-display-separator: block;
    --zoom-background-color: #5b1200;
    --zoom-text-color: #fff1da;
}

:root.theme-orange {
    --background: #ffffff;
    --background-color: #ffffff;
    --cta-background-color: #6B7173;
    --text-color: #ffffff;
    --hashtags-color: #ffffff;
    --pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_light.svg');
    --zoom-pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_light.svg');
    --upshow-logo: url('https://cdn.upshow.tv/ui4_assets/logo_dark.png');
    --upshow-tv-logo: url('https://cdn.upshow.tv/ui4_assets/upshow_tv_logo_black.png');
    --logo-bars-color: #ececec;
    --long-hashtag-cta-background-color: transparent;
    --long-hashtag-cta-color: #6B7173;
    --long-hashtag-hashtags-color: #E95224;
    --long-hashtag-display-separator: block;
    --zoom-background-color: #E95224;
    --zoom-text-color: #ffffff;
}

:root.theme-orange-teal {
    --background: #f47a2d;
    --background-color: #f47a2d;
    --cta-background-color: #ffffff;
    --text-color: #00acae;
    --hashtags-color: #00acae;
    --pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_dark.svg');
    --zoom-pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_light.svg');
    --upshow-logo: url('https://cdn.upshow.tv/ui4_assets/logo_light.png');
    --upshow-tv-logo: url('https://cdn.upshow.tv/ui4_assets/upshow_tv_logo_black.png');
    --logo-bars-color: #ececec;
    --long-hashtag-cta-background-color: transparent;
    --long-hashtag-cta-color: #ececec;
    --long-hashtag-hashtags-color: #00acae;
    --long-hashtag-display-separator: block;
    --zoom-background-color: #f47a2d;
    --zoom-text-color: #ffffff;
}

:root.theme-black {
    --background: #000000;
    --background-color: #000000;
    --cta-background-color: #2f2f2f;
    --text-color: #ececec;
    --hashtags-color: #17b0f4;
    --pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_light.svg');
    --zoom-pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_light.svg');
    --upshow-logo: url('https://cdn.upshow.tv/ui4_assets/logo_light.png');
    --logo-bars-color: #ececec;
    --long-hashtag-cta-background-color: transparent;
    --long-hashtag-cta-color: #ececec;
    --long-hashtag-hashtags-color: #17b0f4;
    --long-hashtag-display-separator: block;
    --zoom-background-color: #2f2f2f;
    --zoom-text-color: #ECECEC;
}

:root.theme-salmon {
    --background: #ffffff;
    --background-color: #ffffff;
    --cta-background-color: #E0605B;
    --text-color: #ffffff;
    --hashtags-color: #ffffff;
    --pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_light.svg');
    --zoom-pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_light.svg');
    --upshow-logo: url('https://cdn.upshow.tv/ui4_assets/logo_dark.png');
    --upshow-tv-logo: url('https://cdn.upshow.tv/ui4_assets/upshow_tv_logo_black.png');
    --logo-bars-color: #ececec;
    --long-hashtag-cta-background-color: transparent;
    --long-hashtag-cta-color: #676767;
    --long-hashtag-hashtags-color: #E0605B;
    --long-hashtag-display-separator: block;
    --zoom-background-color: #E0605B;
    --zoom-text-color: #ffffff;
}

:root.theme-blue-red {
    --background: #ffffff;
    --background-color: #ffffff;
    --cta-background-color: #032471;
    --text-color: #ffffff;
    --hashtags-color: #ffffff;
    --pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_light.svg');
    --zoom-pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_light.svg');
    --upshow-logo: url('https://cdn.upshow.tv/ui4_assets/logo_dark.png');
    --upshow-tv-logo: url('https://cdn.upshow.tv/ui4_assets/upshow_tv_logo_black.png');
    --logo-bars-color: #ececec;
    --long-hashtag-cta-background-color: transparent;
    --long-hashtag-cta-color: #032471;
    --long-hashtag-hashtags-color: #032471;
    --long-hashtag-display-separator: block;
    --zoom-background-color: #032471;
    --zoom-text-color: #ffffff;
}

:root.theme-yellow-red {
    --background: #EEEBE6;
    --background-color: #EEEBE6;
    --cta-background-color: #FEDB31;
    --text-color: #E9212D;
    --hashtags-color: #5B442D;
    --pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_dark.svg');
    --zoom-pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_dark.svg');
    --upshow-logo: url('https://cdn.upshow.tv/ui4_assets/logo_dark.png');
    --upshow-tv-logo: url('https://cdn.upshow.tv/ui4_assets/upshow_tv_logo_black.png');
    --logo-bars-color: #ececec;
    --long-hashtag-cta-background-color: transparent;
    --long-hashtag-cta-color: #E9212D;
    --long-hashtag-hashtags-color: #5B442D;
    --long-hashtag-display-separator: block;
    --zoom-background-color: #FEDB31;
    --zoom-text-color: #E9212D;
}

:root.theme-blue {
    --background: #f8f8f8;
    --background-color: #f8f8f8;
    --cta-background-color: #182f72;
    --text-color: #f8f8f8;
    --hashtags-color: #f8f8f8;
    --pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_light.svg');
    --zoom-pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_light.svg');
    --upshow-logo: url('https://cdn.upshow.tv/ui4_assets/logo_dark.png');
    --upshow-tv-logo: url('https://cdn.upshow.tv/ui4_assets/upshow_tv_logo_black.png');
    --logo-bars-color: #ececec;
    --long-hashtag-cta-background-color: transparent;
    --long-hashtag-cta-color: #182f72;
    --long-hashtag-hashtags-color: #182f72;
    --long-hashtag-display-separator: block;
    --zoom-background-color: #182f72;
    --zoom-text-color: #f8f8f8;
}

:root.theme-blonde {
    --background: #40495F;
    --background-color: #000000;
    --cta-background-color: #40495F;
    --text-color: #f8f8f8;
    --hashtags-color: #F7E598;
    --pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_light.svg');
    --zoom-pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_light.svg');
    --upshow-logo: url('https://cdn.upshow.tv/ui4_assets/logo_light.png');
    --logo-bars-color: #ececec;
    --long-hashtag-cta-background-color: transparent;
    --long-hashtag-cta-color: #ececec;
    --long-hashtag-hashtags-color: #F7E598;
    --long-hashtag-display-separator: block;
    --zoom-background-color: #40495F;
    --zoom-text-color: #f8f8f8;
}

:root.theme-pink-green {
    --background: #f8f8f8;
    --background-color: #f8f8f8;
    --cta-background-color: #F4478E;
    --text-color: #ffffff;
    --hashtags-color: #ffffff;
    --pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_light.svg');
    --zoom-pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_light.svg');
    --upshow-logo: url('https://cdn.upshow.tv/ui4_assets/logo_dark.png');
    --upshow-tv-logo: url('https://cdn.upshow.tv/ui4_assets/upshow_tv_logo_black.png');
    --logo-bars-color: #ececec;
    --long-hashtag-cta-background-color: transparent;
    --long-hashtag-cta-color: #F4478E;
    --long-hashtag-hashtags-color: #F4478E;
    --long-hashtag-display-separator: block;
    --zoom-background-color: #F4478E;
    --zoom-text-color: #ffffff;
}

:root.theme-purple {
    --background: #f8f8f8;
    --background-color: #f8f8f8;
    --cta-background-color: #390B35;
    --text-color: #ffffff;
    --hashtags-color: #E3CFE0;
    --pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_light.svg');
    --zoom-pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_light.svg');
    --upshow-logo: url('https://cdn.upshow.tv/ui4_assets/logo_dark.png');
    --upshow-tv-logo: url('https://cdn.upshow.tv/ui4_assets/upshow_tv_logo_black.png');
    --logo-bars-color: #ececec;
    --long-hashtag-cta-background-color: transparent;
    --long-hashtag-cta-color: #390B35;
    --long-hashtag-hashtags-color: #390B35;
    --long-hashtag-display-separator: block;
    --zoom-background-color: #390B35;
    --zoom-text-color: #ffffff;
}

:root.theme-pink-green-blue {
    --background: #f8f8f8;
    --background-color: #f8f8f8;
    --cta-background-color: #D2007F;
    --text-color: #ffffff;
    --hashtags-color: #C3D601;
    --pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_light.svg');
    --zoom-pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_light.svg');
    --upshow-logo: url('https://cdn.upshow.tv/ui4_assets/logo_dark.png');
    --upshow-tv-logo: url('https://cdn.upshow.tv/ui4_assets/upshow_tv_logo_black.png');
    --logo-bars-color: #ececec;
    --long-hashtag-cta-background-color: transparent;
    --long-hashtag-cta-color: #C3D601;
    --long-hashtag-hashtags-color: #D2007F;
    --long-hashtag-display-separator: block;
    --zoom-background-color: #D2007F;
    --zoom-text-color: #ffffff;
}

:root.theme-black-red {
    --hashtags-color: #ef3e34;
    --long-hashtag-hashtags-color: #ef3e34;
}

:root.theme-black-orange {
    --background: #2f2f2f;
    --background-color: #000000;
    --cta-background-color: #2f2f2f;
    --text-color: #ECECEC;
    --hashtags-color: #F2A124;
    --pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_light.svg');
    --zoom-pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_light.svg');
    --upshow-logo: url('https://cdn.upshow.tv/ui4_assets/logo_light.png');
    --logo-bars-color: #ececec;
    --long-hashtag-cta-background-color: transparent;
    --long-hashtag-cta-color: #ececec;
    --long-hashtag-hashtags-color: #F2A124;
    --long-hashtag-display-separator: block;
    --zoom-background-color: #2f2f2f;
    --zoom-text-color: #ececec;
}

:root.theme-black-yellow {
    --hashtags-color: #ffd200;
    --long-hashtag-hashtags-color: #ffd200;
}

:root.theme-brown-orange {
    --background: #342112;
    --background-color: #f8f8f8;
    --cta-background-color: #342112;
    --text-color: #ffffff;
    --hashtags-color: #F36E24;
    --pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_light.svg');
    --zoom-pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_light.svg');
    --upshow-logo: url('https://cdn.upshow.tv/ui4_assets/logo_light.png');
    --upshow-tv-logo: url('https://cdn.upshow.tv/ui4_assets/upshow_tv_logo_black.png');
    --logo-bars-color: #ececec;
    --long-hashtag-cta-background-color: transparent;
    --long-hashtag-cta-color: #ececec;
    --long-hashtag-hashtags-color: #F36E24;
    --long-hashtag-display-separator: block;
    --zoom-background-color: #342112;
    --zoom-text-color: #ffffff;
}

:root.theme-light-blue {
    --background: #0c589e;
    --background-color: #0c589e;
    --cta-background-color: #30C6F8;
    --text-color: #f8f8f8;
    --hashtags-color: #f8f8f8;
    --pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_dark_whiteup.svg') no-repeat;
    --zoom-pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_light.svg');
    --upshow-logo: url('https://cdn.upshow.tv/ui4_assets/logo_light.png');
    --logo-bars-color: #ececec;
    --long-hashtag-cta-background-color: transparent;
    --long-hashtag-cta-color: #ececec;
    --long-hashtag-hashtags-color: #ececec;
    --long-hashtag-display-separator: block;
    --zoom-background-color: #0c589e;
    --zoom-text-color: #f8f8f8;
}

:root.theme-america {
    --background: #f8f8f8;
    --background-color: #f8f8f8;
    --cta-background-color: #7a1317;
    --text-color: #ffffff;
    --hashtags-color: #ffffff;
    --pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_light.svg');
    --zoom-pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_light.svg');
    --upshow-logo: url('https://cdn.upshow.tv/ui4_assets/logo_dark.png');
    --upshow-tv-logo: url('https://cdn.upshow.tv/ui4_assets/upshow_tv_logo_black.png');
    --logo-bars-color: #ececec;
    --long-hashtag-cta-background-color: transparent;
    --long-hashtag-cta-color: #7a1317;
    --long-hashtag-hashtags-color: #7a1317;
    --long-hashtag-display-separator: block;
    --zoom-background-color: #7a1317;
    --zoom-text-color: #ffffff;
}

:root.theme-black-white {
    --background: #ffffff;
    --background-color: #ffffff;
    --cta-background-color: #ffffff;
    --text-color: #000000;
    --hashtags-color: #000000;
    --pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_dark.svg');
    --zoom-pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_dark.svg');
    --upshow-logo: url('https://cdn.upshow.tv/ui4_assets/logo_dark.png');
    --upshow-tv-logo: url('https://cdn.upshow.tv/ui4_assets/upshow_tv_logo_black.png');
    --upshow-tv-logo-upnext: url('https://cdn.upshow.tv/ui4_assets/upshow_tv_logo_black.png');
    --logo-bars-color: #ececec;
    --long-hashtag-cta-background-color: transparent;
    --long-hashtag-cta-color: #000000;
    --long-hashtag-hashtags-color: #000000;
    --long-hashtag-display-separator: block;
    --zoom-background-color: #ffffff;
    --zoom-text-color: #000000;
}

:root.theme-white-black {
    --background: #000000;
    --background-color: #000000;
    --cta-background-color: #000000;
    --text-color: #ffffff;
    --hashtags-color: #ffffff;
    --pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_light.svg');
    --zoom-pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_light.svg');
    --upshow-logo: url('https://cdn.upshow.tv/ui4_assets/logo_light.png');
    --logo-bars-color: #ececec;
    --long-hashtag-cta-background-color: transparent;
    --long-hashtag-cta-color: #ececec;
    --long-hashtag-hashtags-color: #ffffff;
    --long-hashtag-display-separator: block;
    --zoom-background-color: #000000;
    --zoom-text-color: #ffffff;
}

:root.theme-black-green {
    --background: #2f2f2f;
    --background-color: #000000;
    --cta-background-color: #353535;
    --text-color: #20824e;
    --hashtags-color: #f8f8f8;
    --pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_light.svg');
    --zoom-pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_light.svg');
    --upshow-logo: url('https://cdn.upshow.tv/ui4_assets/logo_light.png');
    --logo-bars-color: #ececec;
    --long-hashtag-cta-background-color: transparent;
    --long-hashtag-cta-color: #ececec;
    --long-hashtag-hashtags-color: #ececec;
    --long-hashtag-display-separator: block;
    --zoom-background-color: #353535;
    --zoom-text-color: #f8f8f8;
}

:root.theme-grey-pink {
    --background: #2f2f2f;
    --background-color: #ececec;
    --cta-background-color: #353535;
    --text-color: #f8f8f8;
    --hashtags-color: #ea3495;
    --pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_light.svg');
    --zoom-pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_light.svg');
    --upshow-logo: url('https://cdn.upshow.tv/ui4_assets/logo_light.png');
    --upshow-tv-logo: url('https://cdn.upshow.tv/ui4_assets/upshow_tv_logo_black.png');
    --logo-bars-color: #ececec;
    --long-hashtag-cta-background-color: transparent;
    --long-hashtag-cta-color: #ececec;
    --long-hashtag-hashtags-color: #ea3495;
    --long-hashtag-display-separator: block;
    --zoom-background-color: #353535;
    --zoom-text-color: #f8f8f8;
}

:root.theme-rustic {
    --background: #d7b6a0 url('https://cdn.upshow.tv/ui4_assets/wood.jpg') no-repeat;
    --background-color: #d7b6a0;
    --cta-background-color: rgba(54, 27, 33, 0.7);
    --text-color: #ffffff;
    --hashtags-color: #17b0f4;
    --pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_light.svg');
    --zoom-pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_light.svg');
    --upshow-logo: url('https://cdn.upshow.tv/ui4_assets/logo_light.png');
    --logo-bars-color: #ececec;
    --long-hashtag-cta-background-color: transparent;
    --long-hashtag-cta-color: #ececec;
    --long-hashtag-hashtags-color: #17b0f4;
    --long-hashtag-display-separator: block;
    --zoom-background-color: #d7b6a0 url('https://cdn.upshow.tv/ui4_assets/wood.jpg') no-repeat;
    --zoom-text-color: #ffffff;
}

:root.theme-candycane {
    --background: #ffffff url('https://cdn.upshow.tv/ui4_assets/candycane.png') no-repeat;
    --background-color: #ffffff;
    --cta-background-color: #e31837;
    --text-color: #000000;
    --hashtags-color: #000000;
    --pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_dark.svg');
    --zoom-pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_dark.svg');
    --upshow-logo: url('https://cdn.upshow.tv/ui4_assets/logo_dark.png');
    --upshow-tv-logo: url('https://cdn.upshow.tv/ui4_assets/upshow_tv_logo_black.png');
    --logo-bars-color: #ececec;
    --long-hashtag-cta-background-color: transparent;
    --long-hashtag-cta-color: #000000;
    --long-hashtag-hashtags-color: #000000;
    --long-hashtag-display-separator: block;
    --zoom-background-color: #ffffff url('https://cdn.upshow.tv/ui4_assets/candycane.png') no-repeat;
    --zoom-text-color: #000000;
}

:root.theme-tarheel-blue {
    --background: #ffffff;
    --background-color: #ffffff;
    --cta-background-color: #7bafd4;
    --text-color: #ffffff;
    --hashtags-color: #ffffff;
    --pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_white.svg') no-repeat;
    --zoom-pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_white.svg') no-repeat;
    --upshow-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_white.svg');
    --upshow-tv-logo: url('https://cdn.upshow.tv/ui4_assets/upshow_tv_logo_black.png');
    --logo-bars-color: #ececec;
    --long-hashtag-cta-background-color: transparent;
    --long-hashtag-cta-color: #7bafd4;
    --long-hashtag-hashtags-color: #7bafd4;
    --long-hashtag-display-separator: block;
    --zoom-background-color: #7bafd4;
    --zoom-text-color: #ffffff;
}

:root.theme-chocolate {
    --background: #3E1707;
    --background-color: #fff1da;
    --cta-background-color: #3E1707;
    --text-color: #64CBE8;
    --hashtags-color: #64CBE8;
    --pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_light.svg');
    --zoom-pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_light.svg');
    --upshow-logo: url('https://cdn.upshow.tv/ui4_assets/logo_dark.png');
    --upshow-tv-logo: url('https://cdn.upshow.tv/ui4_assets/upshow_tv_logo_black.png');
    --logo-bars-color: #ececec;
    --long-hashtag-cta-background-color: transparent;
    --long-hashtag-cta-color: #ececec;
    --long-hashtag-hashtags-color: #17b0f4;
    --long-hashtag-display-separator: block;
    --zoom-background-color: #3E1707;
    --zoom-text-color: #64CBE8;
}

:root.theme-tartan {
    --background: #CF232E url('https://cdn.upshow.tv/ui4_assets/tartan.png') no-repeat;
    --background-color: #CF232E;
    --cta-background-color: #CF232E;
    --text-color: #ffffff;
    --hashtags-color: #ffffff;
    --pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_light.svg');
    --zoom-pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_light.svg');
    --upshow-logo: url('https://cdn.upshow.tv/ui4_assets/logo_light.png');
    --logo-bars-color: #ececec;
    --long-hashtag-cta-background-color: transparent;
    --long-hashtag-cta-color: #ffffff;
    --long-hashtag-hashtags-color: #ffffff;
    --long-hashtag-display-separator: block;
    --zoom-background-color: #CF232E url('https://cdn.upshow.tv/ui4_assets/tartan.png') no-repeat;
    --zoom-text-color: #ffffff;
}

:root.theme-speedzone {
    --background: #000000;
    --background-color: #000000;
    --cta-background-color: #ed1c24;
    --text-color: #000000;
    --hashtags-color: #ed1c24;
    --pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_light.svg');
    --zoom-pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_light.svg');
    --upshow-logo: url('https://cdn.upshow.tv/ui4_assets/logo_light.png');
    --logo-bars-color: #ececec;
    --long-hashtag-cta-background-color: transparent;
    --long-hashtag-cta-color: #ffd600;
    --long-hashtag-hashtags-color: #ed1c24;
    --long-hashtag-display-separator: block;
    --zoom-background-color: #000000;
    --zoom-text-color: #ffd600;
}

:root.theme-dq {
    --background: #EE3E42;
    --background-color: #ffffff;
    --cta-background-color: #EE3E42;
    --text-color: #ffffff;
    --hashtags-color: #ffffff;
    --pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_light.svg');
    --zoom-pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_light.svg');
    --upshow-logo: url('https://cdn.upshow.tv/ui4_assets/logo_light.png');
    --upshow-tv-logo: url('https://cdn.upshow.tv/ui4_assets/upshow_tv_logo_black.png');
    --logo-bars-color: #ececec;
    --long-hashtag-cta-background-color: transparent;
    --long-hashtag-cta-color: #ececec;
    --long-hashtag-hashtags-color: #ffffff;
    --long-hashtag-display-separator: block;
    --zoom-background-color: #EE3E42;
    --zoom-text-color: #ffffff;
}

:root.theme-black-red-white {
    --background: #931A29;
    --background-color: #191718;
    --cta-background-color: #931A29;
    --text-color: #ffffff;
    --hashtags-color: #ffffff;
    --pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_light.svg');
    --zoom-pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_light.svg');
    --upshow-logo: url('https://cdn.upshow.tv/ui4_assets/logo_light.png');
    --logo-bars-color: #ececec;
    --long-hashtag-cta-background-color: transparent;
    --long-hashtag-cta-color: #ececec;
    --long-hashtag-hashtags-color: #ffffff;
    --long-hashtag-display-separator: block;
    --zoom-background-color: #931A29;
    --zoom-text-color: #ffffff;
}

:root.theme-michigan {
    --background: #00274c;
    --background-color: #00274c;
    --cta-background-color: #00274c;
    --text-color: #ffffff;
    --hashtags-color: #ffcb05;
    --pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_light.svg');
    --zoom-pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_light.svg');
    --upshow-logo: url('https://cdn.upshow.tv/ui4_assets/logo_light.png');
    --logo-bars-color: #ececec;
    --long-hashtag-cta-background-color: transparent;
    --long-hashtag-cta-color: #ececec;
    --long-hashtag-hashtags-color: #ffcb05;
    --long-hashtag-display-separator: block;
    --zoom-background-color: #00274c;
    --zoom-text-color: #ffffff;
}

:root.theme-johnny {
    --background: #C42032;
    --background-color: #f8f8f8;
    --cta-background-color: #C42032;
    --text-color: #f8f8f8;
    --hashtags-color: #f8f8f8;
    --pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_light.svg');
    --zoom-pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_light.svg');
    --upshow-logo: url('https://cdn.upshow.tv/ui4_assets/logo_dark.png');
    --upshow-tv-logo: url('https://cdn.upshow.tv/ui4_assets/upshow_tv_logo_black.png');
    --logo-bars-color: #ececec;
    --long-hashtag-cta-background-color: transparent;
    --long-hashtag-cta-color: #ececec;
    --long-hashtag-hashtags-color: #f8f8f8;
    --long-hashtag-display-separator: block;
    --zoom-background-color: #C42032;
    --zoom-text-color: #f8f8f8;
}

:root.theme-grey-green {
    --background: #414141;
    --background-color: #f8f8f8;
    --cta-background-color: #414141;
    --text-color: #8cc63f;
    --hashtags-color: #f8f8f8;
    --pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_light.svg');
    --zoom-pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_light.svg');
    --upshow-logo: url('https://cdn.upshow.tv/ui4_assets/logo_light.png');
    --upshow-tv-logo: url('https://cdn.upshow.tv/ui4_assets/upshow_tv_logo_black.png');
    --logo-bars-color: #ececec;
    --long-hashtag-cta-background-color: transparent;
    --long-hashtag-cta-color: #8cc63f;
    --long-hashtag-hashtags-color: #f8f8f8;
    --long-hashtag-display-separator: block;
    --zoom-background-color: #414141;
    --zoom-text-color: #8cc63f;
}

:root.theme-coke {
    --background: #f70000;
    --background-color: #f8f8f8;
    --cta-background-color: #f70000;
    --text-color: #ffffff;
    --hashtags-color: #ffffff;
    --pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_light.svg');
    --zoom-pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_light.svg');
    --upshow-logo: url('https://cdn.upshow.tv/ui4_assets/logo_dark.png');
    --upshow-tv-logo: url('https://cdn.upshow.tv/ui4_assets/upshow_tv_logo_black.png');
    --logo-bars-color: #ececec;
    --long-hashtag-cta-background-color: transparent;
    --long-hashtag-cta-color: #ececec;
    --long-hashtag-hashtags-color: #ffffff;
    --long-hashtag-display-separator: block;
    --zoom-background-color: #f70000;
    --zoom-text-color: #ffffff;
}

:root.theme-purple-gold {
    --background: #48235D;
    --background-color: #f8f8f8;
    --cta-background-color: #48235D;
    --text-color: #C5B358;
    --hashtags-color: #ffffff;
    --pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_light.svg');
    --zoom-pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_light.svg');
    --upshow-logo: url('https://cdn.upshow.tv/ui4_assets/logo_dark.png');
    --upshow-tv-logo: url('https://cdn.upshow.tv/ui4_assets/upshow_tv_logo_black.png');
    --logo-bars-color: #ececec;
    --long-hashtag-cta-background-color: transparent;
    --long-hashtag-cta-color: #ececec;
    --long-hashtag-hashtags-color: #C5B358;
    --long-hashtag-display-separator: block;
    --zoom-background-color: #48235D;
    --zoom-text-color: #C5B358;
}

:root.theme-red-robin {
    --background: #6A0203;
    --background-color: #f8f8f8;
    --cta-background-color: #6A0203;
    --text-color: #ececec;
    --hashtags-color: #ED9D15;
    --pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_light.svg');
    --zoom-pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_light.svg');
    --upshow-logo: url('https://cdn.upshow.tv/ui4_assets/logo_dark.png');
    --upshow-tv-logo: url('https://cdn.upshow.tv/ui4_assets/upshow_tv_logo_black.png');
    --logo-bars-color: #ececec;
    --long-hashtag-cta-background-color: transparent;
    --long-hashtag-cta-color: #ececec;
    --long-hashtag-hashtags-color: #ED9D15;
    --long-hashtag-display-separator: block;
    --zoom-background-color: #6A0203;
    --zoom-text-color: #ececec;
}

:root.theme-panera {
    --background: #414141;
    --background-color: #f8f8f8;
    --cta-background-color: #414141;
    --text-color: #687819;
    --hashtags-color: #f8f8f8;
    --pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_light.svg');
    --zoom-pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_light.svg');
    --upshow-logo: url('https://cdn.upshow.tv/ui4_assets/logo_light.png');
    --upshow-tv-logo: url('https://cdn.upshow.tv/ui4_assets/upshow_tv_logo_black.png');
    --logo-bars-color: #ececec;
    --long-hashtag-cta-background-color: transparent;
    --long-hashtag-cta-color: #ececec;
    --long-hashtag-hashtags-color: #687819;
    --long-hashtag-display-separator: block;
    --zoom-background-color: #414141;
    --zoom-text-color: #687819;
}

:root.theme-black-gold {
    --background: #2f2f2f;
    --background-color: #000000;
    --cta-background-color: #2f2f2f;
    --text-color: #ECECEC;
    --hashtags-color: #EFC14B;
    --pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_light.svg');
    --zoom-pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_light.svg');
    --upshow-logo: url('https://cdn.upshow.tv/ui4_assets/logo_light.png');
    --logo-bars-color: #ececec;
    --long-hashtag-cta-background-color: transparent;
    --long-hashtag-cta-color: #ececec;
    --long-hashtag-hashtags-color: #EFC14B;
    --long-hashtag-display-separator: block;
    --zoom-background-color: #2f2f2f;
    --zoom-text-color: #ececec;
}

:root.theme-chive {
    --background: #272727;
    --background-color: #000000;
    --cta-background-color: #272727;
    --text-color: #00C300;
    --hashtags-color: #ffffff;
    --pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_light.svg');
    --zoom-pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_light.svg');
    --upshow-logo: url('https://cdn.upshow.tv/ui4_assets/logo_light.png');
    --logo-bars-color: #ececec;
    --long-hashtag-cta-background-color: transparent;
    --long-hashtag-cta-color: #00C300;
    --long-hashtag-hashtags-color: #ffffff;
    --long-hashtag-display-separator: block;
    --zoom-background-color: #272727;
    --zoom-text-color: #00C300;
}

:root.theme-gb {
    --background: #2C5E4F;
    --background-color: #353535;
    --cta-background-color: #2C5E4F;
    --text-color: #FFB612;
    --hashtags-color: #ffffff;
    --pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_light.svg');
    --zoom-pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_light.svg');
    --upshow-logo: url('https://cdn.upshow.tv/ui4_assets/logo_light.png');
    --logo-bars-color: #ececec;
    --long-hashtag-cta-background-color: transparent;
    --long-hashtag-cta-color: #ececec;
    --long-hashtag-hashtags-color: #FFB612;
    --long-hashtag-display-separator: block;
    --zoom-background-color: #2C5E4F;
    --zoom-text-color: #FFB612;
}

:root.theme-hardrock-cafe {
    --background: #2f2f2f;
    --background-color: #000000;
    --cta-background-color: #2f2f2f;
    --text-color: #ECECEC;
    --hashtags-color: #d44d13;
    --pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_light.svg');
    --zoom-pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_light.svg');
    --upshow-logo: url('https://cdn.upshow.tv/ui4_assets/logo_light.png');
    --logo-bars-color: #ececec;
    --long-hashtag-cta-background-color: transparent;
    --long-hashtag-cta-color: #ececec;
    --long-hashtag-hashtags-color: #d44d13;
    --long-hashtag-display-separator: block;
    --zoom-background-color: #2f2f2f;
    --zoom-text-color: #ececec;
}

:root.theme-hardrock-hotel {
    --background: #2f2f2f;
    --background-color: #000000;
    --cta-background-color: #2f2f2f;
    --text-color: #ececec;
    --hashtags-color: #6360a1;
    --pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_light.svg');
    --zoom-pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_light.svg');
    --upshow-logo: url('https://cdn.upshow.tv/ui4_assets/logo_light.png');
    --logo-bars-color: #ececec;
    --long-hashtag-cta-background-color: transparent;
    --long-hashtag-cta-color: #ececec;
    --long-hashtag-hashtags-color: #6360a1;
    --long-hashtag-display-separator: block;
    --zoom-background-color: #2f2f2f;
    --zoom-text-color: #ececec;
}

:root.theme-hardrock-concerts {
    --background: #2f2f2f;
    --background-color: #000000;
    --cta-background-color: #2f2f2f;
    --text-color: #ececec;
    --hashtags-color: #367ab5;
    --pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_light.svg');
    --zoom-pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_light.svg');
    --upshow-logo: url('https://cdn.upshow.tv/ui4_assets/logo_light.png');
    --logo-bars-color: #ececec;
    --long-hashtag-cta-background-color: transparent;
    --long-hashtag-cta-color: #ececec;
    --long-hashtag-hashtags-color: #367ab5;
    --long-hashtag-display-separator: block;
    --zoom-background-color: #2f2f2f;
    --zoom-text-color: #ececec;
}

:root.theme-aveda {
    --background: #392720;
    --background-color: #ececec;
    --cta-background-color: #392720;
    --text-color: #ffffff;
    --hashtags-color: #ffffff;
    // --pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_white.svg') no-repeat;
    // --zoom-pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_white.svg') no-repeat;
    --upshow-logo: url('https://cdn.upshow.tv/ui4_assets/logo_light.png');
    --upshow-tv-logo: url('https://cdn.upshow.tv/ui4_assets/upshow_tv_logo_black.png');
    --logo-bars-color: #ececec;
    --long-hashtag-cta-background-color: transparent;
    --long-hashtag-cta-color: #ececec;
    --long-hashtag-hashtags-color: #ffffff;
    --long-hashtag-display-separator: block;
    --zoom-background-color: #392720;
    --zoom-text-color: #ffffff;
}

:root.theme-poker {
    --background: #29515b url('https://cdn.upshow.tv/ui4_assets/poker.png') no-repeat;
    --background-color: #29515b;
    --cta-background-color: rgba(0, 0, 0, 0.7);
    --text-color: #ffffff;
    --hashtags-color: #cf232e;
    --pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_light.svg');
    --zoom-pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_light.svg');
    --upshow-logo: url('https://cdn.upshow.tv/ui4_assets/logo_light.png');
    --logo-bars-color: #ececec;
    --long-hashtag-cta-background-color: transparent;
    --long-hashtag-cta-color: #ececec;
    --long-hashtag-hashtags-color: #cf232e;
    --long-hashtag-display-separator: block;
    --zoom-background-color: #29515b url('https://cdn.upshow.tv/ui4_assets/poker.png') no-repeat;
    --zoom-text-color: #ffffff;
}

:root.theme-miami {
    --background: #174734;
    --background-color: #ececec;
    --cta-background-color: #174734;
    --text-color: #ffffff;
    --hashtags-color: #EF722F;
    // --pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_white.svg') no-repeat;
    // --zoom-pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_white.svg') no-repeat;
    --upshow-logo: url('https://cdn.upshow.tv/ui4_assets/logo_light.png');
    --upshow-tv-logo: url('https://cdn.upshow.tv/ui4_assets/upshow_tv_logo_black.png');
    --logo-bars-color: #ececec;
    --long-hashtag-cta-background-color: transparent;
    --long-hashtag-cta-color: #ececec;
    --long-hashtag-hashtags-color: #EF722F;
    --long-hashtag-display-separator: block;
    --zoom-background-color: #174734;
    --zoom-text-color: #ffffff;
}

:root.theme-crunch {
    --background: #ececec;
    --background-color: #ececec;
    --cta-background: linear-gradient(to bottom right, #de1245 0%, #ffbc15 100%);
    --cta-background-color: #ffbc15;
    --text-color: #ffffff;
    --hashtags-color: #ffffff;
    // --pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_white.svg') no-repeat;
    // --zoom-pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_white.svg') no-repeat;
    --upshow-logo: url('https://cdn.upshow.tv/ui4_assets/logo_dark.png');
    --upshow-tv-logo: url('https://cdn.upshow.tv/ui4_assets/upshow_tv_logo_black.png');
    --logo-bars-color: #ececec;
    --long-hashtag-cta-background-color: transparent;
    --long-hashtag-cta-color: #ececec;
    --long-hashtag-hashtags-color: #ffffff;
    --long-hashtag-display-separator: block;
    --zoom-background: linear-gradient(to bottom right, #de1245 0%, #ffbc15 100%);
    --zoom-background-color: #ffbc15;
    --zoom-text-color: #ffffff;
}

:root.theme-ebc {
    --background: #000000;
    --background-color: #000000;
    --cta-background: linear-gradient(to bottom right, #11223f 0%, #2a5395 100%);
    --cta-background-color: #11223f;
    --text-color: #ffffff;
    --hashtags-color: #ffffff;
    --long-hashtag-cta-background-color: transparent;
    --pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_white.svg');
    --zoom-pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_white.svg');
    --logo-bars-color: #ffffff;
    --long-hashtag-display-separator: none;
    --zoom-background: linear-gradient(to bottom right, #11223f 0%, #2a5395 100%);
    --zoom-background-color: #11223f;
    --zoom-text-color: #ffffff;
}

:root.theme-hooters {
    --cta-background-color: #ff571f;
    --text-color: #ffffff;
    --hashtags-color: #6b3f22;
    --pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_white.svg');
    --zoom-pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_white.svg');
    --upshow-logo: url('https://cdn.upshow.tv/ui4_assets/logo_dark.png');
    --logo-bars-color: #ececec;
    --long-hashtag-cta-background-color: #ff571f;
    --long-hashtag-cta-color: #ececec;
    --long-hashtag-hashtags-color: #6b3f22;
    --long-hashtag-display-separator: none;
    --zoom-background-color: #ff571f;
    --zoom-text-color: #ffffff;
}

:root.theme-pinstripe {
    --background: #6d2726;
    --background-color: #6d2726;
    --cta-background-color: #6d2726;
    --text-color: #f0f0f0;
    --hashtags-color: #f0f0f0;
    --pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_light.svg');
    --zoom-pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_light.svg');
    --upshow-logo: url('https://cdn.upshow.tv/ui4_assets/logo_light.png');
    --logo-bars-color: #ececec;
    --long-hashtag-cta-background-color: transparent;
    --long-hashtag-cta-color: #f0f0f0;
    --long-hashtag-hashtags-color: #f0f0f0;
    --long-hashtag-display-separator: block;
    --zoom-background-color: #6d2726;
    --zoom-text-color: #f0f0f0;
}

:root.theme-rosatis {
    --background: #2E3130;
    --background-color: #2E3130;
    --cta-background-color: #B20838;
    --text-color: #ffffff;
    --hashtags-color: #2E3130;
    --pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_white.svg');
    --zoom-pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_white.svg');
    --upshow-logo: url('https://cdn.upshow.tv/ui4_assets/logo_light.png');
    --logo-bars-color: #2E3130;
    --long-hashtag-cta-background-color: transparent;
    --long-hashtag-cta-color: #ffffff;
    --long-hashtag-hashtags-color: #2E3130;
    --long-hashtag-display-separator: block;
    --zoom-background-color: #B20838;
    --zoom-text-color: #ffffff;
}

:root.theme-airstrike {
    --background: #2f2f2f;
    --background-color: #000000;
    --cta-background-color: #353535;
    --text-color: #0cf911;
    --hashtags-color: #f8f8f8;
    --pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_light.svg');
    --zoom-pwby-logo: url('https://cdn.upshow.tv/ui4_assets/powered_by_upshow_light.svg');
    --upshow-logo: url('https://cdn.upshow.tv/ui4_assets/logo_light.png');
    --logo-bars-color: #ececec;
    --long-hashtag-cta-background-color: #353535;
    --long-hashtag-cta-color: #0cf911;
    --long-hashtag-hashtags-color: #f8f8f8;
    --long-hashtag-display-separator: block;
    --zoom-background-color: #353535;
    --zoom-text-color: #0cf911;
}

