.DefaultTextCta {
    color: var(--text-color);
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;

    .header {
        font-family: 'Oswald', sans-serif;
        height: 60%;
        width: 100%;
        font-weight: 900;
        box-sizing: border-box;
        .CtaText {
            line-height: 100%;
            box-sizing: border-box;
            height: 100%;
            width: 100%;
        }
    }

    .footer {
        height: 40%;
        width: 100%;
        display: flex;
        flex-direction: column;

       .top{
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-content: center;
            height: 100px;
            .postWith {
                font-family: 'Roboto', sans-serif;
                font-weight: 300;
                width: 70%;
            }
            .icon {
                font-size: 98px;
            }
            .vBar{
                width: 4px;
                background-color: var(--text-color);
                display: block;
                margin: 10px;
                height : calc(100% - 20px);
            }
        }
    
        .bar {
            height: 4px;
            width: 100%;
            border-radius: 3px;
            background-color: var(--text-color);
            display: block;
            margin: 15px 0;
        }

        .bottom{
            flex: 1;
            .postWith{
                font-family: 'Roboto', sans-serif;
                font-weight: 300;
            }
            .CtaHashtags {
                height: 100%;
                font-family: 'Roboto', sans-serif;
                font-weight: 900;
                .hashtags {
                    display: block;
                    text-transform: uppercase;
                    &.long_cta {
                        div {
                            display: inline-block;
                            text-transform: uppercase;
                            &:not(:last-child) {
                                margin-right: 10px;
                            }
                        }
                    }
                }
            }

        }
    }
}

.grid,
.default {
    margin : 10px 30px 0 30px;
    width: calc(100% - 120px);
    height: 100%;
    
    .footer {
        display: flex;
        flex-direction: row;
        align-content: center;
        height: 30%;
        .top {
            display: flex;
            flex-direction: column;
            align-content: center;
            justify-content: space-between;
            width: 110px;
            height: 100%;
            .icon {
                margin: 0 5px;
            }
        }

        .bar {
            width: 4px;
            height: 100%;
            margin: 0 20px;
        }

        .bottom{
            display: flex;
            flex-direction: column;
            .postWith{
                height: 35%;
            }
            .CtaHashtags {
                height: 65%;
                margin: 0;
            } 
        }
    }
}

.altCta {
    margin : 10px 30px 0 30px;
    width: calc(100% - 120px);
    height: 100%;

    .footer{
        display: flex;
        flex-direction: row;
        align-content: center;
        height: 30%;
        .top{
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 15%;
            height: 100%;
            .icon {
                margin: 5px 0;
            }
            margin-right: 20px;
        }
        .bottom{
            display: flex;
            flex-direction: column;
            border-left: 4px solid var(--text-color);
            height: 100%;
            .CtaHashtags {
                display: flex;
                flex-direction: column;
                justify-content: center;
                height: 100%;
                margin-left: 20px;
            } 
        }
    }
}


.slimCta{
    .header {
        height: 65%;
    }
    
    .footer {
        height: 25%;
        .top {
            width: 100px;
            .icon {
                font-size: 85px;
            }
        }
    }
}

.longCta{
    .footer {
        height: 38%;
        .top{
            .icon {
                font-size: 80px;
            }
        }
    }
}

.zoom,
.media {
    .footer {
        .top{
            .postWith{
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
            .vBar{
                background-color : var(--zoom-text-color);
            }
            .icon {
                margin-top: 10px;
                font-size: 80px;
            }
        }
        .bar {
            background-color : var(--zoom-text-color);
        }
    }
}


.media {
    width: calc(100% - 60px);
    height: calc(100% - 60px);
    .header{
        margin: 20px 20px 0 20px;
    }
    .footer{
        .top{
            .vBar{
                height : 100%;
                margin: 0 10px;
                background-color: var(--text-color);
            }
            .icon {
                margin: 1px 3px 0 0;
                font-size: 47px;
            }
        }
        .bar {
            background-color : var(--text-color);
        }
        margin: 0 20px 20px 20px;
    }
}



.LongMedia {
    background-color: var(--zoom-background-color);
    color: var(--zoom-text-color);
    .header{
        height: 50%;
    }
    .footer {
        height: 50%;
        .bottom{
            height: 50%;
        }
        
        .top{
            height: 50%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-content: center;
            .icon {
                margin-right: 10px;
                font-size: 40px;
            }
        }
    }
}