.BackOfHouse.boh_type_huddle_board {
    padding: 5vh 4vw;
    padding-top: calc(5vh + 200px);

    box-sizing: border-box;

    *, *:before, *:after {
        box-sizing: inherit;
    }

    .flex-row {
        display: flex;
    }

    .huddleboard-container {
        height: 100%;
        display: flex;
        justify-content: space-evenly;

        .huddleboard-item:not(:last-child) {
            margin-right: 18px;
        }
    }


    .huddleboard-item {
        display: flex;
        flex-direction: column;

        font-size: 52px;

        padding: 16px;
        border-radius: 6px;
        box-shadow: -10px 10px 20px 0 rgba(30, 30, 30, 0.05);
        border-bottom: 15px solid var(--status-color);
        background-color: white;

        flex: 0 0 430px;
        width: 430px;

        &.status-red {
            --status-color: #C2344A;

        }

        &.status-invalid {
            --status-color: #afafaf;
        }

        &.status-green {
            --status-color: #00AE4C;
        }


        .header {
            display: flex;
            flex-direction: column;

            padding: 24px;

            height: 45%;

            border-bottom: 8px solid #f2f2f2;

            .title {
                font-weight: bold;
                text-transform: uppercase;
                margin-right: 16px;
                flex: 0 0 290px;
                width: 290px;
            }

            .face {
                font-size: 60px;
                color: var(--status-color);
                margin: 0 0 auto;
            }

            .date {
                margin-top: auto;
                font-size: 32px;
                margin-bottom: 16px;
            }
        }

        .body {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            height: 66%;
            font-size: 32px;

            .value {
                font-size: 128px;

                .mini-percentage {
                    font-size: 0.5em;
                }
            }

            .status {
                display: flex;
                align-items: center;
                line-height: 1;

                background-color: var(--status-color);
                color: white;

                padding: 8px 32px;
                margin: 16px 0;
                border-radius: 60px;

                svg {
                    margin-right: 10px;
                }
            }
        }
    }
}
